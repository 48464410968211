import React from 'react';

export default function() {
  return (
    <div className="t-questions__entrance js-questions__entrance">
      <div className="t-questions-top c-grid">
        <div className="c-grid__row">
          <div className="c-grid__col">
            <div className="t-questions-top__ttl">
              <h1 className="t-questions-top__ttl__main p-title p-title--page p-title--white-shadow">
                <span className="js-textEffect js-textEffect--type1">ONLINE</span>
                <span>&nbsp;</span>
                <br className="u-hide-pc" />
                <span className="js-textEffect js-textEffect--type1">COUNSELING</span>
              </h1>
              <h2 className="t-questions-top__ttl__sub">10 QUESTIONS + SKIN CHECK</h2>
            </div>
            <div className="t-questions-top__illust">
              <div className="t-questions-top__illust__chart js-questions__chart">
                <img src="/images/questions/t-questions__illust__chart.png" alt="" />
              </div>
              <div className="t-questions-top__illust__girl js-questions__girl">
                <img src="/images/questions/t-questions__illust__girl.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="c-grid__row">
          <div className="c-grid__col">
            <p className="t-questions-top__txt">
              質問に答えてあなたのスキンケアを
              <br className="u-hide-pc" />
              カスタマイズします。
            </p>
          </div>
        </div>
        <div className="c-grid__row c-grid__row--inner-pd-sp">
          <div className="c-grid__col lg-7 md-3"></div>
          <div className="c-grid__col lg-10 md-6">
            <button className="p-button p-button--circle-right js-questions__start" type="button">
              <span className="p-button__label">START</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
