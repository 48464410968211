import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import T_QUESTION_BG from "../../components/question/t-question_bg"
import T_QUESTION_ENTRANCE from "../../components/question/t-question_entrance"
import T_QUESTION_MODAL from "../../components/question/t-question_modal"
import T_QUESTION_FACE from "../../components/question/t-question_face"
import T_QUESTION_WAIT from "../../components/question/t-question_wait"
const HotaruQuestionPage = () => (
  <Layout sectionClasses={"t-questions js-questions"} dataNamespace={"questions"} isShowFooter={false}>
    <SEO title="ONLINE COUNSELING" />
    <T_QUESTION_BG />
    <T_QUESTION_ENTRANCE />
    <T_QUESTION_MODAL />
    <T_QUESTION_FACE />
    <T_QUESTION_WAIT />
  </Layout>
)

export default HotaruQuestionPage
