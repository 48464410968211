import React from "react";

import question_modal from '../../pug/_object/_project/_questions/_t-questions__modal.pug';

class T_Question_Modal extends React.Component {
    render() {
        return question_modal.call(this, {        // ← use transpiled function
            // // variables
            // items,
            // period,
            // // components
            // ItemDetail,
        });;
    }
}
export default T_Question_Modal