import React from 'react';

export default function() {
  return (
    <div className="t-questions__wait">
      <div className="p-questionsWait js-questionsWait">
        <div className="p-questionsWait__loader">
          <div className="p-questionsWait__outline"></div>
          <div className="p-questionsWait__progress">
            <div className="c-circleProgress js-questionsWait__progress">
              <div className="c-circleProgress__center"></div>
            </div>
          </div>
          <div className="p-questionsWait__text">
            <span className="per"></span>
          </div>
        </div>
      </div>
    </div>
  );
}
