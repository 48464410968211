import React from 'react';

export default function() {
  return (
    <div className="t-questions__modal">
      <div className="p-questionsModal js-questionsModal">
        <div className="p-questionsModal__numbers">
          <div className="p-questionsModal__current js-questionsModal__current"></div>
          <ul className="p-questionsModal__dots js-questionsModal__dots"></ul>
          <div className="p-questionsModal__total js-questionsModal__total"></div>
        </div>
        <div className="p-questionsModal__close">
          <button className="p-icon p-icon--close js-questionsModal__close"></button>
        </div>
        <form className="p-questionsModal__form p-form" action="/products/result/" method="post">
          <ul className="js-questionsForms js-questionsSlide">
            <li className="p-questionsBody">
              <div className="p-questionsBody__head">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col">
                      <h2 className="p-questionsBody__ttl">
                        <span>あなたの名前</span>
                        <span className="u-hide-sp">、</span>
                        <span>
                          または
                          <br className="u-hide-pc" />
                          ニックネームを入力してください。
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__middle">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-5 md-2 xs-0"></div>
                    <div className="c-grid__col lg-14 md-8">
                      <input
                        className="u-tac u-uppercase"
                        type="text"
                        name="name"
                        data-string="alphabet"
                        maxLength="10"
                        placeholder="ローマ字10文字まで"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__foot">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-7 md-3 xs-1"></div>
                    <div className="c-grid__col lg-10 md-6 xs-4">
                      <button className="js-questionsSlide__next p-button p-button--arw-right" data-required="text">
                        <span className="p-button__label">NEXT</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="p-questionsBody">
              <div className="p-questionsBody__head">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col">
                      <h2 className="p-questionsBody__ttl">
                        <span>年齢を教えて下さい。</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__middle">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-5 md-1 xs-0"></div>
                    <div className="c-grid__col lg-7 md-5">
                      <div className="p-form__radio c-checkButton c-checkButton--text">
                        <input type="radio" name="age" value="10代" />
                        <label>
                          <span>10代</span>
                        </label>
                      </div>
                      <div className="p-form__radio c-checkButton c-checkButton--text">
                        <input type="radio" name="age" value="20代前半" />
                        <label>
                          <span>20代前半</span>
                        </label>
                      </div>
                      <div className="p-form__radio c-checkButton c-checkButton--text">
                        <input type="radio" name="age" value="20代後半" />
                        <label>
                          <span>20代後半</span>
                        </label>
                      </div>
                    </div>
                    <div className="c-grid__col lg-7 md-5">
                      <div className="p-form__radio c-checkButton c-checkButton--text">
                        <input type="radio" name="age" value="30代" />
                        <label>
                          <span>30代</span>
                        </label>
                      </div>
                      <div className="p-form__radio c-checkButton c-checkButton--text">
                        <input type="radio" name="age" value="40代" />
                        <label>
                          <span>40代</span>
                        </label>
                      </div>
                      <div className="p-form__radio c-checkButton c-checkButton--text">
                        <input type="radio" name="age" value="50代以上" />
                        <label>
                          <span>50代以上</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__foot">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-5 md-1 xs-0"></div>
                    <div className="c-grid__col lg-5 md-4 xs-1">
                      <button className="js-questionsSlide__back p-button p-button--arw-left p-button--white">
                        <span className="p-button__label u-hide-sp">BACK</span>
                      </button>
                    </div>
                    <div className="c-grid__col lg-9 md-6 xs-5">
                      <button className="js-questionsSlide__next p-button p-button--arw-right" data-required="radio">
                        <span className="p-button__label">NEXT</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="p-questionsBody">
              <div className="p-questionsBody__head">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col">
                      <h2 className="p-questionsBody__ttl">
                        <span>
                          日中のほほの肌状態について
                          <br className="u-hide-pc" />
                          教えて下さい。
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__middle">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-5 md-1 xs-0"></div>
                    <div className="c-grid__col lg-14 md-10">
                      <div className="p-questionsBody__pic-circle c-spacer--pb20 c-spacer--pb0-sp">
                        <img src="/images/questions/p-questionsBody__pic-circle-03.png" alt="" />
                      </div>
                      <div className="p-form__range">
                        <input
                          className="c-rangeSlider js-rangeSlider"
                          type="range"
                          name="daytime-dry-skin"
                          min="0"
                          max="100"
                          step="16.666666666666667"
                          defaultValue="50"
                        />
                        <label>
                          <span>乾燥しない</span>
                          <span>乾燥する</span>
                        </label>
                      </div>
                      <div className="p-form__range">
                        <input
                          className="c-rangeSlider js-rangeSlider"
                          type="range"
                          name="daytime-greasy-skin"
                          min="0"
                          max="100"
                          step="16.666666666666667"
                          defaultValue="50"
                        />
                        <label>
                          <span>脂っぽくない</span>
                          <span>脂っぽい</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__foot">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-5 md-1 xs-0"></div>
                    <div className="c-grid__col lg-5 md-4 xs-1">
                      <button className="js-questionsSlide__back p-button p-button--arw-left p-button--white">
                        <span className="p-button__label u-hide-sp">BACK</span>
                      </button>
                    </div>
                    <div className="c-grid__col lg-9 md-6 xs-5">
                      <button className="js-questionsSlide__next p-button p-button--arw-right">
                        <span className="p-button__label">NEXT</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="p-questionsBody">
              <div className="p-questionsBody__head">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col">
                      <h2 className="p-questionsBody__ttl">
                        <span>夏に肌の乾燥が気になりますか？</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__middle">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-5 md-1 xs-0"></div>
                    <div className="c-grid__col lg-14 md-10">
                      <div className="p-questionsBody__pic-circle c-spacer--pb20 c-spacer--pb30-sp">
                        <img src="/images/questions/p-questionsBody__pic-circle-04.png" alt="" />
                      </div>
                      <div className="p-form__radio c-checkButton c-checkButton--text">
                        <input type="radio" name="summer-dry-skin" value="気になることが多い" />
                        <label>
                          <span>気になることが多い</span>
                        </label>
                      </div>
                      <div className="p-form__radio c-checkButton c-checkButton--text">
                        <input type="radio" name="summer-dry-skin" value="エアコンが当たったりすると、たまに気になる" />
                        <label>
                          <span>
                            エアコンが当たったりすると、
                            <br className="u-hide-pc" />
                            たまに気になる
                          </span>
                        </label>
                      </div>
                      <div className="p-form__radio c-checkButton c-checkButton--text">
                        <input type="radio" name="summer-dry-skin" value="気にならない" />
                        <label>
                          <span>気にならない</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__foot">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-5 md-1 xs-0"></div>
                    <div className="c-grid__col lg-5 md-4 xs-1">
                      <button className="js-questionsSlide__back p-button p-button--arw-left p-button--white">
                        <span className="p-button__label u-hide-sp">BACK</span>
                      </button>
                    </div>
                    <div className="c-grid__col lg-9 md-6 xs-5">
                      <button className="js-questionsSlide__next p-button p-button--arw-right" data-required="radio">
                        <span className="p-button__label">NEXT</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="p-questionsBody">
              <div className="p-questionsBody__head">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col">
                      <h2 className="p-questionsBody__ttl">
                        <span>冬に肌の乾燥が気になりますか？</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__middle">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-5 md-1 xs-0"></div>
                    <div className="c-grid__col lg-14 md-10">
                      <div className="p-questionsBody__pic-circle c-spacer--pb20 c-spacer--pb30-sp">
                        <img src="/images/questions/p-questionsBody__pic-circle-05.png" alt="" />
                      </div>
                      <div className="p-form__radio c-checkButton c-checkButton--text">
                        <input type="radio" name="winter-dry-skin" value="常に気になる" />
                        <label>
                          <span>常に気になる</span>
                        </label>
                      </div>
                      <div className="p-form__radio c-checkButton c-checkButton--text">
                        <input type="radio" name="winter-dry-skin" value="湿気か低い日など、たまに気になる" />
                        <label>
                          <span>湿気か低い日など、たまに気になる</span>
                        </label>
                      </div>
                      <div className="p-form__radio c-checkButton c-checkButton--text">
                        <input type="radio" name="winter-dry-skin" value="気にならない" />
                        <label>
                          <span>気にならない</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__foot">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-5 md-1 xs-0"></div>
                    <div className="c-grid__col lg-5 md-4 xs-1">
                      <button className="js-questionsSlide__back p-button p-button--arw-left p-button--white">
                        <span className="p-button__label u-hide-sp">BACK</span>
                      </button>
                    </div>
                    <div className="c-grid__col lg-9 md-6 xs-5">
                      <button className="js-questionsSlide__next p-button p-button--arw-right" data-required="radio">
                        <span className="p-button__label">NEXT</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="p-questionsBody">
              <div className="p-questionsBody__head">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col">
                      <h2 className="p-questionsBody__ttl">
                        <span>
                          ニキビができやすい箇所を
                          <br className="u-hide-pc" />
                          選択して下さい。（複数選択可）
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__middle">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-2 md-1 xs-0"></div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--card c-spacer--pt10-sp">
                        <input type="checkbox" name="acne-position" value="おでこ" />
                        <label>
                          <img src="/images/questions/c-checkButton--card--acne-forehead.png" alt="" />
                          <span>おでこ</span>
                        </label>
                      </div>
                    </div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--card c-spacer--pt10-sp">
                        <input type="checkbox" name="acne-position" value="あご周り" />
                        <label>
                          <img src="/images/questions/c-checkButton--card--acne-chin.png" alt="" />
                          <span>あご周り</span>
                        </label>
                      </div>
                    </div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--card c-spacer--pt10-sp">
                        <input type="checkbox" name="acne-position" value="フェイスライン" />
                        <label>
                          <img src="/images/questions/c-checkButton--card--acne-faceline.png" alt="" />
                          <span className="u-spacing0">フェイスライン</span>
                        </label>
                      </div>
                    </div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--card c-spacer--pt10-sp">
                        <input type="checkbox" name="acne-position" value="ほほ" />
                        <label>
                          <img src="/images/questions/c-checkButton--card--acne-cheek.png" alt="" />
                          <span>ほほ</span>
                        </label>
                      </div>
                    </div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--card c-spacer--pt10-sp">
                        <input type="checkbox" name="acne-position" value="特になし" data-alone="true" />
                        <label>
                          <img src="/images/questions/c-checkButton--card--acne-none.png" alt="" />
                          <span>特になし</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__foot">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-5 md-1 xs-0"></div>
                    <div className="c-grid__col lg-5 md-4 xs-1">
                      <button className="js-questionsSlide__back p-button p-button--arw-left p-button--white">
                        <span className="p-button__label u-hide-sp">BACK</span>
                      </button>
                    </div>
                    <div className="c-grid__col lg-9 md-6 xs-5">
                      <button className="js-questionsSlide__next p-button p-button--arw-right" data-required="checkbox">
                        <span className="p-button__label">NEXT</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="p-questionsBody">
              <div className="p-questionsBody__head">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col">
                      <h2 className="p-questionsBody__ttl">
                        <span>
                          悩んでいる毛穴を
                          <br className="u-hide-pc" />
                          選択して下さい。（複数選択可）
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__middle">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-2 md-1 xs-0"></div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--card c-spacer--pt10-sp">
                        <input type="checkbox" name="pore-type" value="開き毛穴" />
                        <label>
                          <img src="/images/questions/c-checkButton--card--pore-open.png" alt="" />
                          <span>開き毛穴</span>
                        </label>
                      </div>
                    </div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--card c-spacer--pt10-sp">
                        <input type="checkbox" name="pore-type" value="詰まり毛穴" />
                        <label>
                          <img src="/images/questions/c-checkButton--card--pore-clog.png" alt="" />
                          <span>詰まり毛穴</span>
                        </label>
                      </div>
                    </div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--card c-spacer--pt10-sp">
                        <input type="checkbox" name="pore-type" value="たるみ毛穴" />
                        <label>
                          <img src="/images/questions/c-checkButton--card--pore-slack.png" alt="" />
                          <span>たるみ毛穴</span>
                        </label>
                      </div>
                    </div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--card c-spacer--pt10-sp">
                        <input type="checkbox" name="pore-type" value="黒ずみ毛穴" />
                        <label>
                          <img src="/images/questions/c-checkButton--card--pore-black.png" alt="" />
                          <span>黒ずみ毛穴</span>
                        </label>
                      </div>
                    </div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--card c-spacer--pt10-sp">
                        <input type="checkbox" name="pore-type" value="特になし" data-alone="true" />
                        <label>
                          <img src="/images/questions/c-checkButton--card--pore-none.png" alt="" />
                          <span>特になし</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__foot">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-5 md-1 xs-0"></div>
                    <div className="c-grid__col lg-5 md-4 xs-1">
                      <button className="js-questionsSlide__back p-button p-button--arw-left p-button--white">
                        <span className="p-button__label u-hide-sp">BACK</span>
                      </button>
                    </div>
                    <div className="c-grid__col lg-9 md-6 xs-5">
                      <button className="js-questionsSlide__next p-button p-button--arw-right" data-required="checkbox">
                        <span className="p-button__label">NEXT</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="p-questionsBody">
              <div className="p-questionsBody__head">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col">
                      <h2 className="p-questionsBody__ttl">
                        <span>
                          普段気になる環境を
                          <br className="u-hide-pc" />
                          教えてください。（複数選択可）
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__middle">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-4 md-2 xs-0"></div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--icon c-spacer--pb10 c-spacer--pb0-sp c-spacer--pt10-sp">
                        <input type="checkbox" name="environment" value="空気の乾燥" />
                        <label>
                          <span className="p-environmentIcon type-dry"></span>
                          <span>空気の乾燥</span>
                        </label>
                      </div>
                    </div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--icon c-spacer--pb10 c-spacer--pb0-sp c-spacer--pt10-sp">
                        <input type="checkbox" name="environment" value="花粉" />
                        <label>
                          <span className="p-environmentIcon type-pollen"></span>
                          <span>花粉</span>
                        </label>
                      </div>
                    </div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--icon c-spacer--pb10 c-spacer--pb0-sp c-spacer--pt10-sp">
                        <input type="checkbox" name="environment" value="大気汚染" />
                        <label>
                          <span className="p-environmentIcon type-air"></span>
                          <span>大気汚染</span>
                        </label>
                      </div>
                    </div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--icon c-spacer--pb10 c-spacer--pb0-sp c-spacer--pt10-sp">
                        <input type="checkbox" name="environment" value="紫外線" />
                        <label>
                          <span className="p-environmentIcon type-uv"></span>
                          <span>紫外線</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-4 md-2 xs-0"></div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--icon c-spacer--pt10-sp">
                        <input type="checkbox" name="environment" value="不規則な食事" />
                        <label>
                          <span className="p-environmentIcon type-meal"></span>
                          <span className="u-spacing0">不規則な食事</span>
                        </label>
                      </div>
                    </div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--icon c-spacer--pt10-sp">
                        <input type="checkbox" name="environment" value="疲れ・睡眠不足" />
                        <label>
                          <span className="p-environmentIcon type-sleep"></span>
                          <span className="u-spacing0">疲れ・睡眠不足</span>
                        </label>
                      </div>
                    </div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--icon c-spacer--pt10-sp">
                        <input type="checkbox" name="environment" value="ストレス" />
                        <label>
                          <span className="p-environmentIcon type-stress"></span>
                          <span>ストレス</span>
                        </label>
                      </div>
                    </div>
                    <div className="c-grid__col lg-4 md-2 xs-3">
                      <div className="c-checkButton c-checkButton--icon c-spacer--pt10-sp">
                        <input type="checkbox" name="environment" value="運動不足" />
                        <label>
                          <span className="p-environmentIcon type-exercise"></span>
                          <span>運動不足</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__foot">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-5 md-1 xs-0"></div>
                    <div className="c-grid__col lg-5 md-4 xs-1">
                      <button className="js-questionsSlide__back p-button p-button--arw-left p-button--white">
                        <span className="p-button__label u-hide-sp">BACK</span>
                      </button>
                    </div>
                    <div className="c-grid__col lg-9 md-6 xs-5">
                      <button className="js-questionsSlide__next p-button p-button--arw-right" data-required="checkbox">
                        <span className="p-button__label">NEXT</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="p-questionsBody">
              <div className="p-questionsBody__head">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col">
                      <h2 className="p-questionsBody__ttl">
                        <span>
                          現在悩んでいる肌トラブルを
                          <br className="u-hide-pc" />
                          3つ教えて下さい。
                          <br />
                        </span>
                        <span className="p-questionsBody__ttl__sub">優先順位順にクリックして下さい</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__middle">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-3 md-0 xs-0"></div>
                    <div className="c-grid__col lg-18">
                      <div className="p-questionsBalls js-questionsBalls">
                        <canvas className="p-questionsBalls__canvas" id="cvs-questionsBalls"></canvas>
                        <ul className="p-questionsBalls__list js-questionsBalls__list">
                          <li data-value="stains-and-freckles">
                            シミ
                            <br />
                            そばかす
                          </li>
                          <li data-value="dry">カサつき</li>
                          <li data-value="fine-wrinkles">小じわ</li>
                          <li data-value="pores">
                            毛穴の
                            <br />
                            目立ち
                          </li>
                          <li data-value="makeup-deterioration-and-shine">
                            化粧崩れ
                            <br />
                            テカリ
                          </li>
                          <li data-value="firmness-and-sagging">
                            ハリのなさ
                            <br />
                            たるみ
                          </li>
                          <li data-value="dullness">くすみ</li>
                          <li data-value="acne-and-eruption">
                            ニキビ
                            <br />
                            吹き出物
                          </li>
                          <li data-value="stiff">ごわつき</li>
                        </ul>
                        <input className="js-questionsBalls__input1" type="hidden" name="skin-trouble-1st" value="" />
                        <input className="js-questionsBalls__input2" type="hidden" name="skin-trouble-2nd" value="" />
                        <input className="js-questionsBalls__input3" type="hidden" name="skin-trouble-3rd" value="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__foot">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-5 md-1 xs-0"></div>
                    <div className="c-grid__col lg-14 md-10 c-spacer--pb10 c-spacer--pb10-sp">
                      <button className="p-questionsBalls__reset js-questionsBalls__reset" type="button">
                        選択をリセット
                      </button>
                    </div>
                  </div>
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-5 md-1 xs-0"></div>
                    <div className="c-grid__col lg-5 md-4 xs-1">
                      <button className="js-questionsSlide__back p-button p-button--arw-left p-button--white">
                        <span className="p-button__label u-hide-sp">BACK</span>
                      </button>
                    </div>
                    <div className="c-grid__col lg-9 md-6 xs-5">
                      <button className="js-questionsSlide__next p-button p-button--arw-right" data-required="balls">
                        <span className="p-button__label">NEXT</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="p-questionsBody">
              <div className="p-questionsBody__head">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col">
                      <h2 className="p-questionsBody__ttl">
                        <span>
                          あなたの好きなテクスチャーを
                          <br className="u-hide-pc" />
                          教えて下さい。
                        </span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__middle">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-5 md-1 xs-0"></div>
                    <div className="c-grid__col lg-14 md-10">
                      <div className="p-questionsBody__pic-circle c-spacer--pb20 c-spacer--pb0-sp">
                        <img src="/images/questions/p-questionsBody__pic-circle-10.png" alt="" />
                      </div>
                      <div className="p-form__range">
                        <input
                          className="c-rangeSlider js-rangeSlider"
                          type="range"
                          name="texture"
                          min="0"
                          max="100"
                          defaultValue="50"
                        />
                        <label>
                          <span>しっとり</span>
                          <span>さっぱり</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-questionsBody__foot">
                <div className="c-grid">
                  <div className="c-grid__row">
                    <div className="c-grid__col lg-5 md-1 xs-0"></div>
                    <div className="c-grid__col lg-5 md-4 xs-1">
                      <button className="js-questionsSlide__back p-button p-button--arw-left p-button--white">
                        <span className="p-button__label u-hide-sp">BACK</span>
                      </button>
                    </div>
                    <div className="c-grid__col lg-9 md-6 xs-5">
                      <button className="js-questionsSlide__next p-button p-button--arw-right">
                        <span className="p-button__label">NEXT</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
  );
}
