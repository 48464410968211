import React from 'react';

export default function() {
  return (
    <div className="t-questions__face">
      <div className="p-questionsFace js-questionsFace">
        <div className="p-questionsFace__loader">
          <div className="p-loading c-loading c-loading--zoom-circle">
            <span>WAITING</span>
          </div>
        </div>
        <div className="p-questionsFace__main">
          <div className="p-questionsFace__module js-questionsFace__module" id="YMK-module"></div>
          <input className="js-questionsFace__input" type="hidden" name="face" value="" />
          <div className="p-questionsFace__ok js-questionsFace__ok">
            <span>OK</span>
            <br />
            <span>撮影完了</span>
          </div>
          <div className="p-questionsFace__buttons">
            <button
              className="p-questionsFace__skip js-questionsFace__skip js-questionsFace__end p-button p-button--white p-button--inline p-button--arw-right"
              type="button"
            >
              <span className="p-button__label">SKIP</span>
            </button>
            <button
              className="p-questionsFace__exit js-questionsFace__exit js-questionsFace__end p-button p-button--inline p-button--circle-right"
              type="button"
            >
              <span className="p-button__label">解析して結果を見る</span>
            </button>
          </div>
        </div>
        <div className="p-questionsFace__entrance js-questionsFace__entrance">
          <div className="c-grid">
            <div className="c-grid__row">
              <div className="c-grid__col lg-5 md-2 xs-0"></div>
              <div className="c-grid__col lg-14 md-8 u-tac">
                <h2>
                  <span className="u-block p-title p-title--page p-title--white-shadow c-spacer--pb10">
                    CAMERA SHOOTING
                  </span>
                  <span className="u-block">FOR SKIN CHECK</span>
                </h2>
                <div className="p-questionsFace__entrance__icon">
                  <picture>
                    <source
                      media="(max-width:959px)"
                      srcSet="/images/questions/p-questionsFace__entrance__icon-sp.png"
                    />
                    <img src="/images/questions/p-questionsFace__entrance__icon-pc.png" alt="" />
                  </picture>
                </div>
                <p className="c-spacer--pb50 c-spacer--pb30-sp">
                  肌解析のために、カメラを撮影をおこないます。
                  <br />
                  カメラ機能の使用を「許可」に設定しましょう。
                </p>
              </div>
            </div>
            <div className="c-grid__row">
              <div className="c-grid__col lg-5 md-2 xs-0"></div>
              <div className="c-grid__col lg-7 md-4">
                <button className="js-questionsFace__end p-button p-button--white p-button--arw-right" type="button">
                  <span className="p-button__label">SKIP</span>
                </button>
              </div>
              <div className="c-grid__col lg-7 md-4 c-spacer--pt10-sp">
                <button className="js-questionsFace__start p-button p-button--circle-right" type="button">
                  <span className="p-button__label">START</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
