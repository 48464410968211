import React from 'react';

export default function() {
  return (
    <div className="t-questions__bg">
      <canvas className="t-questions__grad js-questions__grad c-floatingImgs" id="cvs-floatingImgs">
        <img src="/images/common/c-floatingImgs.png" alt="" />
      </canvas>
    </div>
  );
}
